import SwitchAccount from "./SwitchAccount";

const GlobalPopup = (props,popupName) => {
  let popups = {
    SwitchAccount,
  }

  if(popups[popupName]){
    return popups[popupName](props);
  }

  return false;
}

export default GlobalPopup;